import React from 'react';
import { useDispatch } from 'react-redux';
import Layout from '../../components/v2/Layout';
import { Box, Card, CardContent, Grid, IconButton, InputAdornment, InputBase, InputLabel, OutlinedInput, Paper, Snackbar, TextField, Typography } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Button from '../../components/v2/Button';
import { postRedeem } from '../../apis';
import { navigate } from 'gatsby';

const content = {
  'picture_congrat': '',
  'picture_emori': '',
  'header-p1': 'สมาชิก',
  'congrat1': 'ยินดีด้วย',
  'congrat2': 'คุณได้รับของขวัญ!',
  'copied': 'กรุณา copy หรือแคปหน้าจอโค้ดนี้ไปใส่ที่ช่องโปรโมชั่นหน้าชำระเงิน',
  'name': 'Membership card',
};

export default function ResultGift({ location }) {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false);
  const [code, setCode] = React.useState('');

  const handleRedeem = async () => {
    try {
      const payload ={ redeem_code: code }
      let res = await postRedeem(payload);
      if (res.success) {
        navigate('/membership/redeemSuccess');
      }
      setCode('');
    } catch (e) {
      alert('มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง');
    }
  }

  return (
    <Layout
      bg="bg1"
      title={content['header-p1']}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card variant='outlined'>
            <CardContent>
              <Box textAlign='center'>
                <img src={require('../../assets/images/noto_confetti-ball.svg')} />
                <Typography variant='h6' component='h1'>{content['congrat1']}</Typography>
                <Typography variant='h5' component='h2'>{content['congrat2']}</Typography>
                <Typography variant='h5' component='h2'>{content['name']}</Typography>
              </Box>
              <Box style={{ marginTop: '15px'}}>
                <Typography variant='h6' component='h1'>{'กรุณากรอกโค้ด redemption'}</Typography>
                {/* <TextField id='outlined-basic' label='ตย. 1363rcbi5y0hb' variant='outlined' fullWidth /> */}
                <Paper component='form' style={{ margin: '5px 0px 10px 0px'}}>
                  <InputBase
                    fullWidth
                    placeholder='ตย. 1363rcbi5y0hb'
                    style={{ padding: '6px 4px 7px'}}
                    onChange={ event => { setCode(event.target.value) }}
                  />
                </Paper>
              </Box>
              <Button fullWidth onClick={() => handleRedeem()}>{'ยืนยัน'}</Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
}